import request from '@/utils/request'

export function getPage (page, noitems) {
  return request({
    url: '/courses/search?page=' + page + '&item=' + noitems,
    method: 'post'
  })
}
export function getCourses (id) {
  return request({
    url: '/courses/' + id,
    method: 'get'
  })
}
export function search (data) {
  return request({
    url: '/courses/search',
    method: 'post',
    data
  })
}
export function updateCourses (id, data) {
  return request({
    url: '/courses/' + id,
    method: 'put',
    data
  })
}
export function storeCourses (data) {
  return request({
    url: '/courses',
    method: 'post',
    data
  })
}
export function exportExcel (data) {
  return request({
    url: '/courses/export',
    method: 'post',
    responseType: 'blob',
    data
  })
}
export function destroy (id) {
  return request({
    url: '/courses/' + id,
    method: 'delete'
  })
}
