import request from '@/utils/request'

export function uploadFile (data, config) {
  return request({
    url: '/uploadFile',
    method: 'post',
    data,
    ...config
  })
}

export function uploadFileExcel (data, config) {
  return request({
    url: '/courses/upload',
    method: 'post',
    data,
    ...config
  })
}
export function uploadAudio (data, config) {
  return request({
    url: '/upload/audio',
    method: 'post',
    data,
    ...config
  })
}
