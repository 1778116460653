<template>
<div class="app-container">
  <el-col :sm="24" :md="16" >
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>Crear curso</span>
      </div>
      <div class="text item">
        <el-switch
          v-model="activeTransfer"
          active-text="Filtrar empleados"
          inactive-text="No aplica filtro">
        </el-switch>
          <div v-loading="loading" element-loading-text="Cargando" slot="placeholder" class="image-slot">
            <!-- Loading<span class="dot">...</span> -->
          </div>

        <ValidationObserver ref="form" v-slot="{ invalid }">
          <el-form label-position="top" label-width="100px" :model="form">
            <div v-if="activeTransfer">
              <el-form-item>
                <el-upload
                  class="upload-demo"
                  action=""
                  :http-request="postFile"
                  :on-success="handleAvatarSuccessFile">
                  <el-button size="small" type="primary">Clic para subir archivo de excel</el-button>
                  <div slot="tip" class="el-upload__tip">Solo archivos xlsx</div>
                </el-upload>
              </el-form-item>
              <el-form-item>
                <el-transfer
                  filterable
                  :filter-method="filterMethod"
                  filter-placeholder="Buscar por id"
                  v-model="form.transfer"
                  :data="listEmpleyed">
                </el-transfer>
                <!-- <div v-for="itemT in form.transfer" :key="itemT.id">
                  {{itemT}}
                </div> -->
              </el-form-item>
            </div>
              <el-form-item label="Lugar de trabajo">
                 <select v-model="form.workPlace" multiple class="width-100">
                    <option v-for="itemWork in listItemsWorkplace" :key="itemWork.id">
                      {{itemWork}}
                    </option>
                  </select>
              </el-form-item>
              <el-form-item label="Unidad de trabajo" size="mini">
                <select v-model="form.unit" multiple class="width-100">
                    <option v-for="itemUnit in listItemsUnit" :key="itemUnit.id">
                      {{itemUnit}}
                    </option>
                  </select>
              </el-form-item>
               <el-form-item label="Puesto" size="mini">
                 <select v-model="form.job" multiple class="width-100">
                    <option v-for="itemJob in listItemsJob" :key="itemJob.id">
                      {{itemJob}}
                    </option>
                  </select>
              </el-form-item>
              <el-form-item label="Título" size="mini">
                <ValidationProvider name="title" rules="required|max:60" v-slot="{ errors }">
                  <el-input v-model="form.title"></el-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Descripción">
                <tinyeditor v-model="form.description"
                  api-key='epme9s1ejp9yit6h3lokaj05yohsvjm61r9y7kdrvm0wm9ex'
                    :init="{
                      height: 250,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                      ],
                      toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | help'
                    }"
                  />
              </el-form-item>
              <el-form-item label="Imagen">
                <div v-if="form.image">
                  <el-image :src="$urlGlobalApi +form.image">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </div>
                <el-upload
                  action=""
                  drag
                  name="image"
                  :http-request="postImage"
                  :on-success="handleAvatarSuccess"
                  multiple>
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">Suelta tu foto aquí o <em>haz clic para cargar</em></div>
                  <div slot="tip" class="el-upload__tip">Solo archivos jpg/png con un tamaño menor de 1 MB (800 x 450)</div>
                </el-upload>
              </el-form-item>
              <el-form-item label="Duración">
                <ValidationProvider name="duración" rules="required" v-slot="{ errors }">
                  <el-input size="mini" v-model="form.duration" placeholder="3 min"></el-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Orden">
                <ValidationProvider name="orden" rules="required" v-slot="{ errors }">
                  <el-input-number size="mini" v-model="form.order" controls-position="right" :min="0"></el-input-number>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Categorias">
                <ValidationProvider name="categorias" rules="required" v-slot="{ errors }">
                  <el-select
                  size="mini"
                  v-model="form.tags"
                  multiple
                  filterable
                  allow-create
                  placeholder="Comunicación">
                    <el-option
                      v-for="itemT in optionsTags"
                      :key="itemT.value"
                      :label="itemT.label"
                      :value="itemT.value">
                    </el-option>
                  </el-select>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Estatus">
                <el-switch
                  v-model="form.status"
                  active-color="#13ce66"
                  inactive-color="#ff4949">
                </el-switch>
              </el-form-item>
              <el-form-item>
              <el-button
                @click.stop.prevent="onStore"
                type="primary"
                size="mini"
                :disabled="invalid"
                :loading="loading"
                icon="el-icon-upload2">
                Crear</el-button>
              </el-form-item>
          </el-form>
        </ValidationObserver>
      </div>
    </el-card>
  </el-col>
</div>
</template>
<script>
import { storeCourses } from '@/api/courses'
import { uploadImageFile } from '@/api/image'
import { getWorkplace, getUnit, getJob, getUser } from '@/api/listItems'
import { uploadFileExcel } from '@/api/fileUpload'
// import Transfer from '@/views/courses/transfer'

export default {
  // components: {
  //   Transfer
  // },
  data () {
    const generateData = _ => {
      const data = []
      return data
    }
    return {
      form: {
        image: '',
        title: '',
        description: '',
        duration: '',
        order: 0,
        tags: [],
        status: true,
        transfer: [],
        job: [],
        unit: [],
        workPlace: []
      },
      optionsTags: [{
        value: 'Liderazgo',
        label: 'Liderazgo'
      }, {
        value: 'Comunicación interna',
        label: 'Comunicación interna'
      }, {
        value: 'General',
        label: 'General'
      }, {
        value: 'Reconocmientos',
        label: 'Reconocmientos'
      }, {
        value: 'Consejo Editorial',
        label: 'Consejo Editorial'
      }, {
        value: 'Noticia',
        label: 'Noticia'
      }],
      loading: false,
      listItemsWorkplace: {},
      listItemsUnit: {},
      listItemsJob: {},
      listItemsUser: [],
      activeTransfer: false,
      listEmpleyed: [],
      data: generateData()
    }
  },
  created () {
    this.listWorkplace()
    this.listUnit()
    this.listJob()
    this.listUser()
  },
  methods: {
    async listUser () {
      this.loading = true
      await getUser()
        .then(response => {
          // this.listItemsUser = response.data
          this.listEmpleyed = []
          for (var item of response.data) {
            this.listEmpleyed.push({
              key: item.idEmployed,
              label: item.idEmployed + ' ' + item.name
            })
          }
          // this.loading = false
          // console.log('loading ', this.loading)
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async postFile (param) {
      this.loading = true
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('alt', 'Avigrupo-file-Excel')
      uploadFileExcel(formData).then((response) => {
        console.log(response)
        param.onSuccess(response)
        this.$message({
          showClose: true,
          message: 'Se ha cargado el archivo!',
          type: 'success'
        })
      })
        .catch(response => {
          console.log(response)
          param.onError()
        })
        .finally(() => (this.loading = false))
    },
    handleAvatarSuccessFile (res, file) {
      // this.form.path = res.path
      console.log(res.data[0])
      // this.listEmpleyed = []
      for (var item of res.data[0]) {
        console.log(item[0])
        this.form.transfer.push(item[0])
      }
      // console.log(this.form)
    },
    filterMethod: function (query, item) {
      // console.log(item, query)
      return item.key.toString().indexOf(query) > -1
    },
    success: function () {
      this.$notify({
        message: 'Listado cargado con exito',
        type: 'success',
        duration: 0
      })
    },
    async listWorkplace () {
      this.loading = true
      await getWorkplace()
        .then(response => {
          this.listItemsWorkplace = response.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async listUnit () {
      this.loading = true
      await getUnit()
        .then(response => {
          this.listItemsUnit = response.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async listJob () {
      this.loading = true
      await getJob()
        .then(response => {
          this.listItemsJob = response.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async postImage (param) {
      this.loading = true
      const formData = new FormData()
      formData.append('image', param.file)
      formData.append('folder', 'img/cursos')
      formData.append('width', '800')
      formData.append('height', '450')
      formData.append('title', 'Courses-create')
      formData.append('alt', 'Courses')
      uploadImageFile(formData).then((response) => {
        console.log(response)
        param.onSuccess(response.data.path)
      })
        .catch(response => {
          console.log(response)
          param.onError()
        })
        .finally(() => (this.loading = false))
    },
    async onStore () {
      // console.log(this.value)
      // console.log(this.form)
      await storeCourses(this.form)
        .then(() => {
          // let res = response.data
          this.$message({
            showClose: true,
            message: '¡Se ha creado con exito!',
            type: 'success'
          })
          this.$router.push({ name: 'courses' })
        })
        .catch(this.responseCatch)
    },
    handleAvatarSuccess (res, file) {
      this.form.image = res
    }
  }
}
</script>
